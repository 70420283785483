$(document).ready(() => {
    $("#contact_form").on('submit', e => {
        e.preventDefault()

        const form = $(e.target)[0]

        const data = new FormData(form)

        $.ajax({
            type: 'POST',
            url: 'contact/form',
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            beforeSend: () => {
                $('.error_field').removeClass('error_field')
            },
            success: response => {
                form.reset()

                notifySuccess(response.message)
            },
            error: (response) => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, errors) => {
                        $(`input[name="${key}"]`).addClass('error_field')
                    })
                } else {
                    notifyError()
                }
            }
        })
    });
})
