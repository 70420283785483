$(document).ready(() => {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    window.notifySuccess = (message, title) => {
        toastr.success(message, title, {timeOut: 1500});
    };

    window.notifyWarning = (message, title) => {
        toastr.warning(message, title, {timeOut: 1500});
    };

    window.notifyError = (message = 'Что-то пошло не так :(', title) => {
        toastr.error(message, title, {timeOut: 1500});
    };
})
