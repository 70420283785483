$(document).ready(() => {

    $('.product_request').on('click', e => {
        e.preventDefault()

        $('input[name="product_request_type"]').val($(e.target).data('type'))
    })

    $(".product-request__form").on('submit', e => {
        e.preventDefault()

        let valuesIds = []
        $('.product-props__val--selected').each((i, item) => {
            valuesIds.push($(item).data('valueId'))
        })

        let optionsIds = []
        $('.product-option:checked').each((i, item) => {
            optionsIds.push($(item).val())
        })

        const form = $(e.target)[0]

        const data = new FormData(form)

        data.append('valuesIds', valuesIds)
        data.append('optionsIds', optionsIds)
        data.append('product_request_price', $('.product_price').html())

        $.ajax({
            type: 'POST',
            url: 'product/order',
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            beforeSend: () => {
                $('.error_field').removeClass('error_field')
            },
            success: response => {
                form.reset()

                notifySuccess(response.message)
            },
            error: (response) => {
                if (response.status === 422) {
                    $.each(response.responseJSON.errors, (key, errors) => {
                        $(`input[name="product_request_${key}"]`).addClass('error_field')
                    })
                } else {
                    notifyError();
                }
            }
        })
    });
})
