$(document).ready(() => {
    $(".product-props__val").on('click', e => {
        e.preventDefault()

        const listParent = $(e.currentTarget).parent()
        listParent.children().each((i, item) => {
            $(item).removeClass('product-props__val--selected')
        })
        $(e.currentTarget).addClass('product-props__val--selected')

        let valuesIds = []
        $('.product-props__val--selected').each((i, item) => {
            valuesIds.push($(item).data('valueId'))
        })

        $.ajax({
            type: 'POST',
            url: 'product/price',
            data: {
                slug: $('#product_slug').data('slug'),
                values_ids: valuesIds,
            },
            dataType: 'json',
            success: response => {
                $('.product_price').html(response.price)
            },
            error: (xhr, status, err) => {}
        })
    });
})
