window.$ = window.jQuery = require('jquery')

window.toastr = require("toastr/toastr");


/* BASE */
require('./base/init');
require('./base/helpers');
require('./base/meta');
require('./base/language');
require('./base/errorsFields');

/* PROJECT */
require('./project/vendors/svgxuse');
require('./project/vendors/slick');
require('./project/vendors/modal');
require('./project/vendors/imask');
require('./project/main');
require('./project/product-price');
require('./project/product-order');
require('./project/contact');
require('./project/vendors/jquery.fancybox.min');

$(document).ready(() => {
    $('.meta_btn').on('click', e => {
        e.preventDefault()

        if ($('.meta_modal').hasClass('meta_modal_show')) {
            $('.meta_modal').removeClass('meta_modal_show')
        } else {
            $('.meta_modal').addClass('meta_modal_show')
        }
    })
})





