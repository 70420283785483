$(function () {
    $("html").removeClass("no-js").addClass("js");

    $(".header-toggle").on("click", function () {
        $("body").toggleClass("is-menu-opened");
    });

    $("body").on("show.bs.modal", function (e) {
        $("body").removeClass("is-menu-opened");
    });

    $(".instagram-slider").slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow:
            "<button type='button' class='instagram-slider__prev instagram-slider__arrow'><svg class='icons arrow-left'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-left'></use></svg></button>",
        nextArrow:
            "<button type='button' class='instagram-slider__next instagram-slider__arrow'><svg class='icons arrow-right'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-right'></use></svg></button>",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    arrows: false,
                },
            },
        ],
    });

    $(".priorities-slider").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        fade: true,
        dots: true,
    });

    $(".product-slider").slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
            "<button type='button' class='instagram-slider__prev instagram-slider__arrow'><svg class='icons arrow-left'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-left'></use></svg></button>",
        nextArrow:
            "<button type='button' class='instagram-slider__next instagram-slider__arrow'><svg class='icons arrow-right'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-right'></use></svg></button>",
    });

    $(".product-view-slider__wrapper").slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        appendArrows: ".product-view-slider",
        prevArrow:
            "<button type='button' class='product-view-slider__prev product-view-slider__arrow'><svg class='icons arrow-left'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-left'></use></svg></button>",
        nextArrow:
            "<button type='button' class='product-view-slider__next product-view-slider__arrow'><svg class='icons arrow-right'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-right'></use></svg></button>",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    infinite: false,
                    arrows: false,
                },
            },
        ],
    });

    $(".about-feedback-slider").slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow:
            "<button type='button' class='instagram-slider__prev instagram-slider__arrow'><svg class='icons arrow-left'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-left'></use></svg></button>",
        nextArrow:
            "<button type='button' class='instagram-slider__next instagram-slider__arrow'><svg class='icons arrow-right'><use xlink:href='/images/project/svg/sprite-icons.svg#arrow-right'></use></svg></button>",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: false,
                },
            },
            {
                breakpoint: 410,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                },
            },
        ],
    });

    $(".product-view__toggle").on("click", function () {
        $(".product-view").toggleClass("product-view--opened");
    });

    $("#move-to-up").on("click", function () {
        $("html, body").animate({scrollTop: 0}, "fast");
    });

    const phoneField = document.getElementById("phone-mask");
    if (phoneField) {
        IMask(phoneField, {mask: "+{375} (00) 000-00-00"});
    }

    // Иницализация Fancybox без дополнительных кнопок
    $('[data-fancybox]').fancybox({
        buttons: ["zoom", "close"],
        closeExisting: true
    });
});
