$(document).ready(() => {
    $("#meta_form").on('submit', e => {
        e.preventDefault()

        const form = $(e.target)

        $.ajax({
            type: 'POST',
            url: form.data('action'),
            data: form.serialize(),
            dataType: 'json',
            success: response => {
                notifySuccess('обновлено');
            },
            error: (xhr, status, err) => {
                notifyError('обновление не удалось');
            }
        })
    });
})
